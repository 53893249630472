.main {
  :global {
    .ant-modal-close-x {
      padding: 0;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.ui-full-modal-panel {
  top: 62px !important;
  width: 95%;
  height: calc(100vh - 92px);

  .fullscreen {
    max-height: 100% !important;
  }

  .ant-modal-body {
    padding: 0 !important;
    height: calc(100vh - 222px);
    position: relative;
    overflow: hidden;

    > {
      height: 100%;
    }


    .ui-modal-scroll-helper {
      padding: 24px;
    }
  }
}