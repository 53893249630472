.modal-upload-image {
  :global {
    .ant-checkbox-wrapper {
      position: relative;

      img {
        height: 60px;
        width: 60px;
      }

      .ant-checkbox {
        position: absolute;
        top: 0;
        right: 5px;
      }
    }
  }
}