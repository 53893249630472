//
// _pagination.scss
//
// Pagination Separated
.pagination-separated {
  .page-item {
    .page-link {
      margin-left: 0.35rem;
      border-radius: $border-radius;
    }
  }
}

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px !important;
    border: none;
    min-width: 32px;
    min-height: 32px;
    text-align: center;
  }

  &.pagination-sm {
    .page-link {
      min-width: 25px;
      min-height: 25px;
    }
  }
}

.page-item.active {
  .page-link {
    box-shadow: $element-shadow;
  }
}

.ant-pagination-item {
  min-width: 38px !important;
}
.ant-pagination-item > a {
  border: none;
  background-color: #fff;
  color: var(--vz-link-color);
  font-weight: 500;
  border-radius: 0.25rem !important;
}
.ant-pagination-item-active > a {
  background-color: var(--color-primary);
  color: #fff !important;
  border-color: var(--color-primary);
}
.ant-pagination .ant-pagination-item-active {
  border-color: #fff !important;
}
.ant-pagination-next > button,
.ant-pagination-prev > button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 14px !important;
  border: 1px solid #cfcfcf73 !important;
  width: 38px !important;
  border-radius: 0.25rem !important;
  background-color: #a9a7d333 !important;
}

.ant-pagination-options-quick-jumper {
  display: none !important;
}
