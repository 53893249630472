//
// vector-maps.scss
//

.jvm-tooltip {
    border-radius: 3px;
    background-color: var(--color-primary);
    font-family: $font-family-base;
    box-shadow: $box-shadow-lg;
    padding: 5px 9px;
}

.jvm-container {
    text {
        font-family: $font-family-base;
        font-size: $font-size-base;
        fill: var(--#{$prefix}gray-700);
    }
}

.jvm-zoom-btn {
    background-color: var(--color-primary);
}