// :root CSS dark variables

[data-layout-mode="dark"] {
  /* #region  Color system - Dark Mode only */

  --color-primary: #5252e0;
  --color-hover-primary: #5252e0cc;
  --color-disabled-primary: #5252e033;
  //
  --color-secondary: #3577f1;
  --color-hover-primary: #3577f1cc;
  --color-disabled-primary: #3577f133;
  //
  --color-success: #0ab39c;
  --color-hover-primary: #0ab39ccc;
  --color-disabled-primary: #0ab39c33;
  //
  --color-info: #299cdb;
  --color-hover-primary: #299cdbcc;
  --color-disabled-primary: #299cdb33;
  //
  --color-warning: #f7b84b;
  --color-hover-primary: #f7b84bcc;
  --color-disabled-primary: #f7b84b33;
  //
  --color-danger: #f06548;
  --color-hover-primary: #f06548cc;
  --color-disabled-primary: #f0654833;
  //
  --color-light: #a9a7d333;
  --color-hover-primary: #a9a7d333;
  --color-disabled-primary: #a9a7d333;
  //
  --color-dark: #212529;
  --color-hover-primary: #212529;
  --color-disabled-primary: #212529;
  /* #endregion*/

  /* #region css body */
  --color-navbar-bg: #292e32;
  --color-body-bg: #{$gray-100};
  --color-card-system: #292e32;
  --color-sub-text: #737185;
  --color-icon: #151334;

  --#{$prefix}border-color: #8886b06b;
  --#{$prefix}body-color: #{$gray-100};
  /* #endregion*/

  $primary: #5252e1;
  $secondary: var(--color-secondary);
  $success: var(--color-success);
  $info: var(--color-info);
  $warning: var(--color-warning);
  $danger: var(--color-danger);
  $light: var(--color-light);
  $dark: var(--color-dark);

  $theme-colors: (
    "primary": #5252e1,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  );

  /* #region css card */
  .card {
    --#{$prefix}card-bg: var(--color-card-system);
    --#{$prefix}card-cap-bg: var(--color-card-system);
  }
  --#{$prefix}card-bg-custom: var(--color-card-system);
  --#{$prefix}card-logo-dark: none;
  --#{$prefix}card-logo-light: block;
  /* #endregion*/

  //vertical dark
  --#{$prefix}vertical-menu-item-hover-color: #{$primary};
  --#{$prefix}vertical-menu-item-active-color: #{$primary};
  --#{$prefix}vertical-menu-sub-item-color: #{darken($gray-600, 4%)};
  --#{$prefix}vertical-menu-sub-item-hover-color: #{$primary};
  --#{$prefix}vertical-menu-sub-item-active-color: #{$primary};
  --#{$prefix}vertical-menu-title-color: #919da9;

  // Topbar - Dark
  --#{$prefix}header-bg: #292e32;
  --#{$prefix}header-item-color: #e9ecef;
  // Topbar Search
  --#{$prefix}topbar-search-bg: #292e32;
  // Topbar User
  --#{$prefix}topbar-user-bg: #{lighten($gray-100, 3%)};

  //
  --#{$prefix}footer-bg: #292e32;
  --#{$prefix}footer-color: #{$gray-400};

  // Horizontal nav
  --#{$prefix}topnav-bg: #{lighten($gray-200, 2.5%)};
  --#{$prefix}topnav-item-color: #{$gray-400};
  --#{$prefix}topnav-item-color-active: #{$white};

  // twocolumn menu
  --#{$prefix}twocolumn-menu-iconview-bg: #{$primary};
  --#{$prefix}twocolumn-menu-bg: #{$white};

  // two column dark
  --#{$prefix}twocolumn-menu-iconview-bg-dark: var(--#{$prefix}vertical-menu-bg-dark);
  --#{$prefix}twocolumn-menu-bg-dark: #30363a;
  --#{$prefix}twocolumn-menu-item-color-dark: var(--#{$prefix}vertical-menu-item-color-dark);
  --#{$prefix}twocolumn-menu-item-active-color-dark: #{$primary};
  --#{$prefix}twocolumn-menu-item-active-bg-dark: #{rgba($primary, 0.15)};

  // boxed
  --#{$prefix}boxed-body-bg: #{darken($gray-100, 4%)};

  // heading-color
  --#{$prefix}heading-color: #{$gray-700};

  // component variable
  --#{$prefix}light: #{$gray-300};
  --#{$prefix}light-rgb: #{to-rgb($gray-300)};
  --#{$prefix}dark: #{$gray-800};
  --#{$prefix}dark-rgb: #{to-rgb($gray-800)};

  // link
  --#{$prefix}link-color: #{$primary};
  --#{$prefix}link-hover-color: #{$primary};

  // Border variable
  --#{$prefix}border-color: #{lighten($gray-300, 3.5%)};

  // dropdown
  .dropdown-menu {
    --#{$prefix}dropdown-bg: #{lighten($gray-200, 3.5%)};
    --#{$prefix}dropdown-link-color: #{$gray-500};
    --#{$prefix}dropdown-link-hover-color: #{shade-color($gray-700, 10%)};
    --#{$prefix}dropdown-link-hover-bg: #{lighten($gray-300, 2%)};
    --#{$prefix}dropdown-border-width: 0;
    --#{$prefix}dropdown-link-active-color: #{$gray-500};
    --#{$prefix}dropdown-link-active-bg: #{lighten($gray-300, 2%)};
  }

  //choices
  .choices,
  .flatpickr-calendar,
  .ck,
  .ql-toolbar,
  .select2-container,
  .menu-dropdown {
    --#{$prefix}choices-bg: #{lighten($gray-200, 3.5%)};
    --#{$prefix}choices-link-color: #{$gray-500};
    --#{$prefix}choices-link-hover-bg: #{lighten($gray-300, 2%)};
    --#{$prefix}choices-link-active-color: #{shade-color($gray-900, 10%)};
  }

  //buttons
  .btn-light {
    --#{$prefix}btn-color: var(--#{$prefix}dark);
    --#{$prefix}btn-bg: var(--#{$prefix}light);
    --#{$prefix}btn-border-color: var(--#{$prefix}light);
  }

  .btn-outline-light {
    --#{$prefix}btn-color: var(--#{$prefix}dark);
    --#{$prefix}btn-bg: transparent;
    --#{$prefix}btn-border-color: var(--#{$prefix}light);
  }

  .btn-light,
  .btn-outline-light {
    --#{$prefix}btn-hover-color: var(--#{$prefix}dark);
    --#{$prefix}btn-hover-bg: rgba(var(--#{$prefix}light-rgb), 0.75);
    --#{$prefix}btn-hover-border-color: rgba(var(--#{$prefix}light-rgb), 0.75);
  }

  .btn-dark {
    --#{$prefix}btn-color: var(--#{$prefix}light);
    --#{$prefix}btn-bg: var(--#{$prefix}dark);
    --#{$prefix}btn-border-color: var(--#{$prefix}dark);
  }

  .btn-outline-dark {
    --#{$prefix}btn-color: var(--#{$prefix}dark);
    --#{$prefix}btn-bg: transparent;
    --#{$prefix}btn-border-color: var(--#{$prefix}dark);
  }

  .btn-dark,
  .btn-outline-dark {
    --#{$prefix}btn-hover-color: var(--#{$prefix}light);
    --#{$prefix}btn-hover-bg: rgba(var(--#{$prefix}dark-rgb), 0.75);
    --#{$prefix}btn-hover-border-color: rgba(var(--#{$prefix}dark-rgb), 0.75);
  }

  // modal
  .modal {
    --#{$prefix}modal-bg: #{$gray-200};
  }

  // nav tabs
  .nav-tabs {
    --#{$prefix}nav-tabs-link-active-color: #{$gray-900};
    --#{$prefix}nav-tabs-link-active-bg: #{$gray-300};
  }

  // accordion
  .accordion {
    --#{$prefix}accordion-button-active-color: #{$white};
    --#{$prefix}accordion-bg: #{$gray-200};
    --#{$prefix}accordion-btn-bg: #{$gray-200};
  }

  //offcanvas
  :is(.offcanvas, .offcanvas-lg, .offcanvas-md, .offcanvas-sm, .offcanvas-xl, .offcanvas-xxl) {
    --#{$prefix}offcanvas-bg: #{$gray-900};
  }

  // progress
  .progress {
    --#{$prefix}progress-bg: #{$gray-300};
  }

  //table
  .table {
    --vz-table-striped-bg: #{rgba($white, 0.03)};
    --vz-table-active-bg: #{rgba($white, 0.07)};
    --vz-table-hover-bg: #{rgba($white, 0.07)};
  }

  .table-light {
    --vz-table-color: #{$white};
    --vz-table-bg: #{rgba($white, 0.05)};
    --vz-table-border-color: #{$border-color};
    --vz-table-striped-bg: #{rgba($white, 0.07)};
    --vz-table-striped-color: #{$white};
  }

  // toast
  .toast {
    --#{$prefix}toast-bg: #{rgba($gray-300, 0.85)};
    --#{$prefix}toast-header-bg: #{rgba($gray-300, 0.85)};
    --#{$prefix}toast-border-color: #{rgba($white, 0.1)};
    --#{$prefix}toast-header-border-color: #{rgba($white, 0.05)};
  }

  //list
  --#{$prefix}list-group-hover-bg: #{lighten($gray-200, 2.5%)};

  // popover
  .popover {
    --#{$prefix}popover-bg: #{lighten($gray-200, 1%)};
  }

  // pagination
  .pagination {
    --#{$prefix}pagination-hover-bg: #{lighten($gray-200, 4%)};
    --#{$prefix}pagination-focus-bg: #{lighten($gray-200, 4%)};
  }

  //form
  --#{$prefix}input-bg: #{lighten($gray-200, 2%)};
  --#{$prefix}input-border: #{$gray-300};
  --#{$prefix}input-focus-border: #{lighten($gray-300, 4%)};
  --#{$prefix}input-disabled-bg: #{$gray-200};

  // input-group-addon
  --#{$prefix}input-group-addon-bg: #{$gray-300};

  //check
  --#{$prefix}input-check-border: #{lighten($gray-300, 4%)};
}

//sidebar color
[data-sidebar="gradient"] {
  --#{$prefix}vertical-menu-bg-gradient: linear-gradient(to right, var(--#{$prefix}primary), var(--#{$prefix}success));
  --#{$prefix}vertical-menu-border-gradient: var(--#{$prefix}success);
  --#{$prefix}twocolumn-menu-bg-dark: var(--#{$prefix}success);
}

[data-sidebar="gradient-2"] {
  --#{$prefix}vertical-menu-bg-gradient: linear-gradient(to right, var(--#{$prefix}info), var(--#{$prefix}secondary));
  --#{$prefix}vertical-menu-border-gradient: var(--#{$prefix}secondary);
  --#{$prefix}twocolumn-menu-bg-dark: var(--#{$prefix}secondary);
}

[data-sidebar="gradient-3"] {
  --#{$prefix}vertical-menu-bg-gradient: linear-gradient(to right, var(--#{$prefix}info), var(--#{$prefix}success));
  --#{$prefix}vertical-menu-border-gradient: var(--#{$prefix}success);
  --#{$prefix}twocolumn-menu-bg-dark: var(--#{$prefix}success);
}

[data-sidebar="gradient-4"] {
  --#{$prefix}vertical-menu-bg-gradient: linear-gradient(to right, #1a1d21, var(--#{$prefix}primary));
  --#{$prefix}vertical-menu-border-gradient: var(--#{$prefix}primary);
  --#{$prefix}twocolumn-menu-bg-dark: var(--#{$prefix}primary);
}
